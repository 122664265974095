import React, { createContext, useState, useContext, useEffect } from 'react';
import { getJwtToken } from '../auth';
import config from '../config';

const reactServer = config.reactServer;

interface ProfileContextType {
  profile: any; // Replace 'any' with proper interface for your profile data
  loadingProfile: boolean;
  errorProfile: string | null;
}

const ProfileContext = createContext<ProfileContextType | undefined>(undefined);

export function ProfileProvider({ children }: { children: React.ReactNode }) {
  const [profile, setProfile] = useState(null);
  const [loadingProfile, setLoadingProfile] = useState(true);
  const [errorProfile, setErrorProfile] = useState<string | null>(null);

  useEffect(() => {
    const token = getJwtToken();

    if (token) {
      fetch(reactServer + "account/profile", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          setProfile(data);
          setLoadingProfile(false);
        })
        .catch((error) => {
          console.error("Error fetching profile:", error);
          setErrorProfile(error.message);
          setLoadingProfile(false);
        });
    }
  }, []);

  return (
    <ProfileContext.Provider value={{ profile, loadingProfile, errorProfile }}>
      {children}
    </ProfileContext.Provider>
  );
}

export function useProfile() {
  const context = useContext(ProfileContext);
  if (context === undefined) {
    throw new Error('useProfile must be used within a ProfileProvider');
  }
  return context;
}