export const getJwtToken = (): string | null => {
  // Retrieve the token from localStorage
  return localStorage.getItem("jwtToken");
};

export const logout = (): void => {
  // Remove the token from localStorage on logout
  localStorage.removeItem("jwtToken");
  localStorage.removeItem("tolk_chat_id");
  localStorage.removeItem("scribe_chat_id");
};
