/**
 * Main application component that handles routing, authentication, and layout
 * @file App.tsx
 */

import React, { useState, useEffect, useRef, Suspense, lazy } from "react";
import {
  HashRouter as Router,
  Routes,
  Route,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";


import useConnectivityStatus from "./functions/useConnectivityStatus";
import { getJwtToken } from "./auth";
import config from "./config";
import "./App.css";
import LandingPage from "./pages/landingpage";

// Import wakelock
import { useWakeLock } from 'react-screen-wake-lock';

// Loading in the elements using Lazy loading where neccesary
import LogoAnimation from "./logoAnimation";
import { release } from "os";
import { ProfileProvider } from "./functions/ProfileContext";
import { useProfile } from "./functions/ProfileContext";
const Error = lazy(() => import('./notifications/Error'));
const Neutral = lazy(() => import('./notifications/Neutral'));
const TolkPage = lazy(() => import('./tolkchat'));
const ScribeChat = lazy(() => import('./scribechat'));
const LoginPage = lazy(() => import('./pages/login'));
const RegisterPage = lazy(() => import('./pages/register'));
const Profiel = lazy(() => import('./pages/profiel'));
const Abonnementen = lazy(() => import('./pages/abonnement'));
const Contract = lazy(() => import('./pages/contract'));
const WellcomOverzicht = lazy(() => import('./pages/wellcomoverzicht'));
const ProfielOverzichtBedrijf = lazy(() => import('./pages/bedrijfprofieloverzicht'));
const Ontwikkeling = lazy(() => import('./pages/ontwikkeling'));
const DemoPilot = lazy(() => import('./pages/demopilot'));
const ContactFeedbackModal = lazy(() => import('./pages/contactfeedbackmodal'));
const FAQModal = lazy(() => import('./pages/faqmodal'));
const ChatPage = lazy(() => import('./pages/qrchat'));
const WachtwoordVergeten = lazy(() => import('./pages/wachtwoordVergeten'));
const ResetPassword = lazy(() => import('./pages/wachtwoordNieuw'));
const WellcomBedrijfOverzicht = lazy(() => import('./pages/wellcombedrijfoverzicht'));
const PaymentSuccess = lazy(() => import('./pages/paymentsuccess'));
const PatientManagement = lazy(() => import('./pages/patientmanagement'));
const Invoices = lazy(() => import('./pages/invoices'));
const Statistieken = lazy(() => import('./pages/statistieken'));

  
// TO-DO: only add a few components here
// Preload components
const preloadComponents = () => {
  import('./tolkchat');
  import('./scribechat');
  import('./pages/profiel');
  import('./pages/login');
  import('./pages/register');
  import('./pages/abonnement');
};

const reactServer = config.reactServer;

/**
 * Initial application wrapper that handles the logo animation
 * before rendering the main application content
 */
function App() {

  const [animationFinished, setAnimationFinished] = useState(false);

  const handleAnimationEnd = () => {
    setAnimationFinished(true);
  };

  return (
    <ProfileProvider>
      <>
        {!animationFinished && (
          <LogoAnimation onAnimationEnd={handleAnimationEnd} />
        )}
        {animationFinished && (
          <Router>
            <AppContent />
          </Router>
        )}
      </>
    </ProfileProvider>
  );
}

/**
 * Main application component that handles routing, navigation,
 * and global state management
 */
function AppContent() {
  // Navigation and UI state
  const [isHovered, setIsHovered] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  // Server connectivity and user feedback state
  const { isOnline, isServerReachable } = useConnectivityStatus();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [neutralMessage, setNeutralMessage] = useState<string | null>(null);

  // TolkChat navigation confirmation state
  const [isInTolkChat, setIsInTolkChat] = useState(false);
  const [showNavConfirmationModal, setShowNavConfirmationModal] = useState(
    false
  );
  const [pendingNavigationTarget, setPendingNavigationTarget] = useState("");
  const [navConfirmationMessage, setNavConfirmationMessage] = useState("");

  // Router hooks
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setIsInTolkChat(location.pathname.includes("/tolkchat"));
  }, [location]);

  // WakeLock
  const {
    isSupported: isWakeLockSupported,
    released: wakeLockReleased,
    request: requestWakeLock,
    release: releaseWakeLock
  } = useWakeLock({
    onRequest: () => console.log('Schermvergrendeling aangevraagd!'),
    onError: () => console.log('Schermvergrendeling: error!'),
    onRelease: () => console.log('Schermvergrendeling uitgezet!'),
  });

  const { profile, loadingProfile, errorProfile } = useProfile();

  // Add effects to handle profile loading state and errors
  useEffect(() => {
    if (loadingProfile) {
      console.log('Loading profile...');
    }
  }, [loadingProfile]);

  // Add effect to handle profile loading errors
  useEffect(() => {
    if (errorProfile) {
      setErrorMessage(`Er is een fout opgetreden bij het laden van uw profiel: ${errorProfile}`);
    }
  }, [errorProfile]);

  /**
   * Fetches user profile data on component mount if user is authenticated
   */
  useEffect(() => {
    // Preloading
    preloadComponents();
  }, []);


  /**
   * Handles scroll events to update navigation bar styling
   */
  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScrolled(isScrolled);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);


  /**
   * Handles navigation with confirmation for TolkChat
   * @param path - Target route path
   * @param event - Click event
   */
  // Function to set the chat_started value (to false)
  const setChatStartedCookie = (value: boolean) => {
    document.cookie = `chat_started=${value}; path=/`;
  };

  const getChatStartedCookie = (): boolean => {
    const name = 'chat_started=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');
    // is this really neccesary?
    for (let i = 0; i < cookieArray.length; i++) {
      let cookie = cookieArray[i].trim();
      if (cookie.indexOf(name) === 0) {
        return cookie.substring(name.length, cookie.length) === 'true';
      }
    }
    return false;
  };


  const handleNavigation = (
    path: string,
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    // TO-DO: hier moet geimplementeere worden een check of er een gesprek gestart is
    const isChatStarted = getChatStartedCookie();
    if (isChatStarted) {
      event.preventDefault();
      setNavConfirmationMessage(
        "Uw gespreksdata zal verloren gaan. Weet u zeker dat u het huidige gesprek wilt verlaten?"
      );
      setPendingNavigationTarget(path);
      setShowNavConfirmationModal(true);
    } else {
      event.preventDefault();
      // Close modals before navigation
      setShowFeedbackModal(false);
      setShowFaqModal(false);
      if (path === location.pathname) {
        window.location.reload();
      } else {
        navigate(path);
        setMenuOpen(false);
      }
    }
  };

  const confirmNavigation = () => {
    setShowNavConfirmationModal(false);
    setChatStartedCookie(false);

    // Close modals before navigation
    setShowFeedbackModal(false);
    setShowFaqModal(false);

    releaseWakeLock();

    if (pendingNavigationTarget === location.pathname) {
      window.location.reload();
    } else {
      navigate(pendingNavigationTarget);
      setMenuOpen(false);
    }
    setPendingNavigationTarget("");
    setNavConfirmationMessage("");
  };

  const cancelNavigation = () => {
    setShowNavConfirmationModal(false);
    setPendingNavigationTarget("");
    setNavConfirmationMessage("");
  };

  useEffect(() => {
    if (!isOnline) {
      setErrorMessage("Geen internet. Check uw verbinding");
    } else if (!isServerReachable) {
      setErrorMessage(
        "Kan de server niet bereiken. Probeer het later opnieuw."
      );
    } else {
      setErrorMessage(null);
    }
  }, [isOnline, isServerReachable]);

  const handleCloseError = () => setErrorMessage(null);
  const handleCloseNeutral = () => setNeutralMessage(null);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1050);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1050);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (menuOpen) {
      document.body.classList.add("menu-open");
    } else {
      document.body.classList.remove("menu-open");
    }
  }, [menuOpen]);

  const menuRef = useRef<HTMLDivElement>(null);
  const menuButtonRef = useRef<HTMLDivElement>(null);

  /**
   * Handles clicks outside mobile menu to close it
   */
  useEffect(() => {
    if (menuOpen) {
      const handleClickOutside = (event: MouseEvent) => {
        if (
          menuRef.current &&
          !menuRef.current.contains(event.target as Node) &&
          !menuButtonRef.current?.contains(event.target as Node)
        ) {
          setMenuOpen(false);
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [menuOpen]);


  // For the Contact Feedback Modal
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);

  // For the FAQ Modal
  const [showFaqModal, setShowFaqModal] = useState(false);

  // Add effect to close modals on location change
  useEffect(() => {
    setShowFeedbackModal(false);
    setShowFaqModal(false);
  }, [location]);

  return (

    <div>
      {/* NAV BAR */}
      <nav className={`navigation ${scrolled ? "scrolled" : ""}`}>
        <div className={`nav-container ${isMobile ? "ismobile" : ""}`}>
          {isMobile ? (
            <>
              <div className="nav-mobile-container">
                <div
                  className={`ham-menu ${menuOpen ? "active" : ""}`}
                  onClick={() => setMenuOpen(!menuOpen)}
                  ref={menuButtonRef}
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
                <Link
                  to="/landingpage"
                  onClick={(e) => handleNavigation("/landingpage", e)}
                >
                  <img
                    src="./logo.png"
                    alt="Logo"
                    className="nav-logo-mobile"
                  />
                </Link>
              </div>
            </>
          ) : (
            <div className="nav-links">
              {profile?.is_admin ? (
                <Link
                  className="nav-link"
                  to="/wellcomoverzicht"
                  onClick={(e) => handleNavigation("/wellcomoverzicht", e)}
                >
                  Wellcom Overzicht
                </Link>
              ) : profile?.is_org_admin ? (
                <Link
                  className="nav-link"
                  to="/mijnbedrijf"
                  onClick={(e) => handleNavigation("/mijnbedrijf", e)}
                >
                  Mijn Bedrijf
                </Link>
              ) : (
                <Link
                  className="nav-link"
                  to="https://wellcomtranslation.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Waarom Wellcom?
                </Link>
              )}

              {/* Contact / Feedback link */}
              <Link
                className="nav-link"
                to="#"
                onClick={(e) => {
                  e.preventDefault();
                  setShowFeedbackModal(true);
                }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                {profile?.naam ? "Feedback" : "Contact"}
              </Link>

              <Link className="nav-link show-on-mobile-only" to={"/tolkchat"}>
                TolkChat
              </Link>

              {profile?.is_admin ? (
                <Link
                  className="nav-link"
                  to="/wellcombedrijfoverzicht"
                  onClick={(e) => handleNavigation("/wellcombedrijfoverzicht", e)}
                >
                  Bedrijfoverzicht
                </Link>
              ) : (
                <Link
                  className="nav-link"
                  to={profile?.naam ? "/tolkchat" : "/login"}
                  onClick={
                    profile?.naam
                      ? (e) => handleNavigation("/tolkchat", e)
                      : (e) => handleNavigation("/login", e)
                  }
                >
                  {profile?.naam ? "TolkChat" : "Start Chat"}
                </Link>
              )}

              <Link
                className="nav-link"
                to={profile?.naam ? "/landingpage" : "/login"}
                onClick={(e) =>
                  handleNavigation(profile?.naam ? "/landingpage" : "/login", e)
                }
              >
                <img src="./logo.png" alt="Logo" className="nav-logo" />
              </Link>

              <Link
                className="nav-link"
                to={profile?.naam ? "/scribechat" : "/ontwikkeling"}
                onClick={(e) =>
                  handleNavigation(
                    profile?.naam ? "/scribechat" : "/ontwikkeling",
                    e
                  )
                }
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                {profile?.naam ? "ScribeChat" : "Ontwikkeling"}
              </Link>

              {/* Instead of linking to /faq, open the FAQ modal */}
              <Link
                className="nav-link nav-center"
                to="#"
                onClick={(e) => {
                  e.preventDefault();
                  setShowFaqModal(true);
                }}
              >
                <>
                  Help <img src="/help.png" alt="" className="help-icon" />
                </>
              </Link>

              <Link
                className="nav-link show-on-mobile"
                to={profile?.naam ? "/profiel" : "/login"}
                onClick={(e) =>
                  handleNavigation(profile?.naam ? "/profiel" : "/login", e)
                }
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                {profile?.naam ? "Profiel" : "Login"}
              </Link>
            </div>
          )}
        </div>

        {/* MOBILE MENU DROPDOWN */}
        {isMobile && menuOpen && (
          <div className="nav-links mobile open" ref={menuRef}>
            {profile?.is_admin || profile?.is_org_admin ? (
              <Link
                className="nav-link"
                to="/wellcomoverzicht"
                onClick={(e) => { handleNavigation("/wellcomoverzicht", e); setMenuOpen(false); }}
              >
                Wellcom overzicht
              </Link>
            ) : (
              <Link
                className="nav-link"
                to="https://wellcomtranslation.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Waarom Wellcom?
              </Link>
            )}

            <Link
              className="nav-link"
              to={profile?.naam ? "/tolkchat" : "/login"}
              onClick={(e) => { handleNavigation(profile?.naam ? "/tolkchat" : "/login", e); setMenuOpen(false); }
              }
            >
              TolkChat
            </Link>

            <Link
              className="nav-link"
              to={"/scribechat"}
              onClick={(e) => { handleNavigation("/scribechat", e); setMenuOpen(false); }
              }
            >
              ScribeChat
            </Link>

            {/* Open the Contact/Feedback modal */}
            <Link
              className="nav-link"
              to="#"
              onClick={(e) => {
                e.preventDefault();
                setShowFeedbackModal(true);
                setMenuOpen(false);
              }}
            >
              {profile?.naam ? "Feedback" : "Contact"}
            </Link>

            {/* Instead of linking to /faq, we open FAQ modal */}
            <Link
              className="nav-link"
              to="#"
              onClick={(e) => {
                e.preventDefault();
                setShowFaqModal(true);
                setMenuOpen(false);
              }}
            >
              <>
                Help <img src="/help.png" alt="" className="help-icon" />
              </>
            </Link>

            {profile?.is_admin ? (
              <Link
                className="nav-link"
                to="/wellcombedrijfoverzicht"
                onClick={(e) => { handleNavigation("/wellcombedrijfoverzicht", e); setMenuOpen(false); }
                }

              >
                Bedrijfoverzicht
              </Link>
            ) : null}

            <Link
              className="nav-link"
              to={profile?.naam ? "/profiel" : "/login"}
              onClick={(e) => { handleNavigation(profile?.naam ? "/profiel" : "/login", e); setMenuOpen(false); }
              }
            >
              {profile?.naam ? "Profiel" : "Login"}
            </Link>
          </div>
        )}
      </nav>

      {/* MAIN CONTENT */}
      <div className="main-content">
        <Suspense fallback={<div></div>}>
          <Routes>
            {/* Removed: <Route path="/faq" element={<FAQ />} /> */}
            <Route
              path="/"
              element={profile?.naam ? <TolkPage /> : <LoginPage />}
            />
            {/* <Route path="/contact" element={<ContactPage />} /> */}
            {/* <Route path="/waarom" element={<WaaromPage />} /> */}
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
            {/* <Route
            path="/register-organisation"
            element={<RegisterOrganisation />}
          />
          <Route
            path="/register-10-plus-organisation"
            element={<RegisterTenPlus />}
          /> */}

            <Route path="/tolkchat" element={<TolkPage />} />
            <Route path="/scribechat" element={<ScribeChat />} />
            {/* <Route path="/history" element={<History />} /> */}
            <Route path="/profiel" element={<Profiel />} />
            <Route path="/abonnement" element={<Abonnementen />} />
            <Route path="/factuur" element={<Invoices />} />
            <Route path="/contract" element={<Contract />} />
            <Route path="/wellcomoverzicht" element={<WellcomOverzicht />} />
            <Route path="/mijnbedrijf" element={<ProfielOverzichtBedrijf />} />
            {/* <Route path="/translate" element={<TranslationPage />} /> */}
            <Route path="/ontwikkeling" element={<Ontwikkeling />} />
            <Route path="/demopilot" element={<DemoPilot />} />
            <Route path="/chat/:conversationId" element={<ChatPage />} />
            <Route path="/new_chat/:conversationId" element={<ChatPage />} />
            <Route path="/wachtwoord-vergeten" element={<WachtwoordVergeten />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            <Route path="/wellcombedrijfoverzicht" element={<WellcomBedrijfOverzicht />} />
            <Route path="/payment-success" element={<PaymentSuccess />} />
            <Route path="/patient-management" element={<PatientManagement />} />
            <Route path="/statistieken" element={<Statistieken />} />
            <Route path="/landingpage" element={<LandingPage />} />
          </Routes>
        </Suspense>

      </div>


      {/* FOOTER */}
      <footer className="footer">
        <div className="social-links">
          <a
            href="https://www.linkedin.com/company/wellcom-translation/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="./linkedin-logo.png" alt="LinkedIn" />
          </a>
          <a
            href="https://www.wellcom-translation.nl"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="./globe-logo.png" alt="Website" />
          </a>
        </div>
        <div className="additional-links">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setShowFaqModal(true);
            }}
          >
            Help
          </a>
          <Link
            to="/ontwikkeling"
            onClick={(e) => handleNavigation("/ontwikkeling", e)}
          >
            Ontwikkeling
          </Link>
          <a href="/./bestanden/Privacyverklaring.pdf" download>
            Privacy Statement
          </a>
          <a href="/./bestanden/Usage_Manual.pdf" download>
            Gebruikershandleiding
          </a>
        </div>
        <div className="copyright">
          {new Date().getFullYear()} Wellcom. all rights reserved.
        </div>
      </footer>

      {/* ERROR + NEUTRAL NOTIFICATIONS */}
      {errorMessage && (
        <Error message={errorMessage} onClose={handleCloseError} />
      )}
      {neutralMessage && (
        <Neutral message={neutralMessage} onClose={handleCloseNeutral} />
      )}

      {/* NAV-CONFIRMATION MODAL WHEN LEAVING TOLKCHAT */}
      {showNavConfirmationModal && (
        <div className="modal-overlay">
          <div className="modal">
            <h2>Gesprek Verlaten?</h2>
            <p>{navConfirmationMessage}</p>
            <div className="modal-buttons">
              <button className="cancel-leave-button" onClick={cancelNavigation}>
                Annuleren
              </button>
              <button className="bevestig-leave-button" onClick={confirmNavigation}>
                Bevestigen
              </button>
            </div>
          </div>
        </div>
      )}

      {/* The Contact Feedback Modal */}
      <ContactFeedbackModal
        isOpen={showFeedbackModal}
        onClose={() => setShowFeedbackModal(false)}
      />

      {/* The FAQ Modal */}
      <FAQModal isOpen={showFaqModal} onClose={() => setShowFaqModal(false)} />
    </div>
  );
}

export default App;
